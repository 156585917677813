<template>
    <label class="conditional-edit">
        <div>{{label}}</div>        
        <div class="">
            <template v-if="editing">
                <template v-if="options">
                    <select @change="change">
                        <option v-for="(option, key) in options" :key="key" :selected="key === record[prop]" :value="key">{{option}}</option>
                    </select>
                </template>
                <template v-else-if="record.typeof[prop] === Filepath">
                    A FILE PATH LINK {{record[prop]}}
                    <file-input></file-input>
                </template>
                <template v-else-if="Array.isArray(record.typeof[prop])">
                    
                </template>
                <template v-else-if="record.typeof[prop] === Date">
                    <input type="date" :value="record[prop]" />
                </template>
                <template v-else> 
                    <input type="text" :value="record[prop]" @change="change" />
                </template>
            </template>
            <template v-else>
                <template v-if="record.typeof[prop] === Filepath">
                    A FILE PATH LINK {{record[prop]}}
                </template>
                <template v-else-if="record.typeof[prop] === Date">
                    {{record[prop].toLocaleDateString('en-US')}}
                </template>
                <template v-else-if="record.typeof[prop] === Email">
                    <a :href="'mailto:'+ record[prop]">{{record[prop]}}</a>
                </template>
                <template v-else> 
                    {{record[prop]}}
                </template>
            </template>
        </div>
    </label>
</template>

<script>
import Email from '../../models/Email'
import Filepath from '../../models/Filepath'
import FileInput from './FileInput.vue'

export default {
  components: { FileInput },
    props: [
        'editing',
        'record',
        'prop',
        'label',
        'options'
    ],
    methods: {
        change: function(e){
            this.$emit("change", this.prop, e.target.value);
        }
    },
    data: function(){
        return {
            Filepath: Filepath,
            Email: Email
        }
    }
}
</script>