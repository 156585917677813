<template>
<form @submit.prevent="save" class="user-details-form" v-if="record">
    <div class="subcomponent">
        <div> 
            <h3>User Details</h3>
            <table class="headless-table">
                <tbody>
                    <tr>
                        <td>Full name</td>
                        <td>
                            <conditional-edit :editing="editing" :record="record" @change="change" prop="full-name"></conditional-edit>
                        </td>
                    </tr>
                    <tr>
                        <td>Email address</td>
                        <td>
                            <conditional-edit :editing="editing" :record="record" @change="change" prop="email"></conditional-edit>
                        </td>
                    </tr>
                    <tr>
                        <td>User role</td>
                        <td>
                            <conditional-edit :editing="editing" :record="record" @change="change" :options="record.typeof['user-role']" prop="user-role"></conditional-edit>
                        </td>
                    </tr>
                    <tr>
                        <td>User status</td>
                        <td>
                            <conditional-edit :editing="editing" :record="record" @change="change" :options="record.typeof['user-status']" prop="user-status"></conditional-edit>
                        </td>
                    </tr>
                    <tr>
                        <td>Last active date</td>
                        <td>
                            {{record['last-active-date'] ? record['last-active-date'].toLocaleDateString('en-US') : ''}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="permissions"> 
            <h3>Permissions</h3>
            <open-multiselect 
                v-if="record['permissions'] && editing" 
                :options="record.typeof['permissions'][0]" 
                :values="values.valueof['permissions']"
                name="permissions"
                @change="change"
                >
            </open-multiselect>
            <ul v-else-if="record['permissions']" class="check-list">
                <li v-for="permission in record.typeof.permissions[0]" :key="permission" :class="{'negative' : record.permissions.indexOf(permission) === -1}">
                    {{permission}}
                </li>
            </ul>
        </div>

        <div class="locations"> 
            <h3>Inspector Locations</h3>
            <open-multiselect 
                v-if="record['locations'] && editing" 
                :options="record.typeof['locations'][0]" 
                :values="values.valueof['locations']"
                name="locations"
                @change="change"
                >
            </open-multiselect>
            <p v-else-if="!record['locations'] || !Object.keys(record['locations']).length">No locations assigned to this user.</p>
            <ul v-else>
                <li v-for="location in record['locations']" :key="location">
                    {{location}}
                </li>
            </ul>
        </div>

        <div class="accounts"> 
            <h3>Accounts</h3>
            <open-multiselect 
                v-if="record['accounts'] && editing" 
                :options="record['accounts'][0]['related-accounts']" 
                prop="name"
                :values="values.valueof['accounts']"
                name="accounts"
                @change="change"
                >
            </open-multiselect>
            <p v-else-if="!record['accounts'] || !Object.keys(record['accounts']).length">No accounts are linked to this user.</p>
            <table v-else>
                <thead>
                    <tr>
                        <th>Account #</th>
                        <th>Account Name</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="account in record['accounts']" :key="account.id" class="link" @click.stop="$router.push('/accounts/' + account.id)">
                        <table-cell :row="account" column="number"></table-cell>
                        <table-cell :row="account" column="name"></table-cell>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <transition name="autocordion">
        <div class="autocordion" v-if="editing">
            <div class="form-controls">
                <button class="button-2" @click.prevent="cancel">Cancel</button>
                <button type="submit">Save</button>
            </div>
        </div>
    </transition>

</form>
</template>

<script>
import UserModel from '../../models/UserModel';
import ConditionalEdit from '../inputs/ConditionalEdit.vue';
import OpenMultiselect from '../inputs/OpenMultiselect.vue';
import TableCell from '../tables/TableCell.vue';

export default {
    components: {
        ConditionalEdit,
        OpenMultiselect,
        TableCell,
    },
    props: [
        'editing',
        'record',
    ],
    data: function(){
        return {
            values: {}
        }
    },
    methods: {
        save: function(){
            //do the updating of the record
            console.log(this.values.valueof);
            this.$root.$data[this.record.constructor.name][this.record.id].populate(this.values.valueof);
            this.$emit('stop-editing');
        },
        cancel: function(){
            if (!confirm("You will lose any changes")){
                return;
            }
            this.values = {};
            this.$emit('stop-editing');
        },
        change: function(prop, newValue){
            this.values[prop] = newValue;
        }
    },
    watch: {
        editing: function(isEditing){
            if (isEditing){
                this.values = new UserModel(this.record.valueof);
            }
        }
    }
}
</script>


<style lang="scss">
@import "../../assets/scss/variables.scss";

.user-details-form{
    .listbuilder{
        margin-top: $space-standard;
    }
    .accounts{
        min-width: 400px;

        table{
            width: 100%;
            border: 1px solid nth($grays,9);
        }
    }
    .headless-table{
        td {
            width: 50%;
        }
    }
}
</style>