<template>
    <div class="multiselect open-multiselect">
        <ul class="multiselect-list">
            <li v-for="(label, value) in options" :key="value">
                <label @click="select(value)" :class="{selected: values.indexOf(value) !== -1}">{{getLabel(label, prop)}}</label>
            </li>
        </ul>
    </div>
</template>

<script>

export default {
    props: [
        'name',
        'values',
        'options',
        'prop'
    ],
    data: function(){
        console.log(this.options)
        return {
        }
    },
    methods: {
        select: function(value){
            const i = this.values.indexOf(value);
            let newValue = this.values.slice();
            if(i !== -1){
                newValue.splice(i, 1);
                this.$emit("change", this.name, newValue);
            } else {
                newValue.push(value);
                this.$emit("change", this.name, newValue);    
            }
        },
        getLabel: function(row){
            return this.prop ? row[this.prop] : row;
        }
    }
}
</script>

<style lang="scss">
@import "../../assets/scss/variables.scss";
.multiselect.open-multiselect{
    height: auto;

    label{
        padding-left: 0;

        &:hover{
            box-shadow:-5px 0 0 nth($brands, 10);
        }
    }
}
</style>